import { navigate } from "gatsby";
import { logout } from "../services/auth";
import { Additional_Services_Name, Additional_Services, B2C_Tier, CloudB2C_ProductIDs, B2B2C_Tier, CloudB2B2C_ProductIDs, Client_Type, System_Type, DesktopB2C_ProductIDs, B2X } from "./constants";
import { CustomAUMLimitDropdown_B2B2C, CustomAUMLimitDropdown_B2C } from "./dropdowns";
var HTMLParser = require('node-html-parser');
const queryString = require('query-string');

var dateFormat = require('dateformat');

export function processResponse(response) {
    const statusCode = response.status;
    const data = response.json();
    return Promise.all([statusCode, data]).then(res => {
        console.log(res);
        return {
          error: {status:res[0],stack:res[1]},
          response: res[1]
      }
    }).then(({error,response}) => {
        if (error.status === 401) {
            logout(navigateToLogin);
        }

        return {error, response};
    })
}

export const navigateToLogin = () => {
    navigate('/login');
}

export function getAccountPrefix(token) {
    return token || true ? '/account' : '';
}

export const formatDate = (date, format='d mmmm, yyyy') => {
    return date && (new Date(date) > new Date('1/1/1900')) ? dateFormat(date,format) : '';
}

export const createUrlQueryParams = (url, params) => {
    url = url+'?';
    Object.keys(params).forEach(key => {
        if (params[key] !== null && params[key] !== undefined) {
            url = url+key+'='+params[key]+'&';
        }
    });
    url = url.slice(0,-1);
    return url;
}

export function escapeRegExp(text) {
    return text.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
}

export function strip(html){
    var root = HTMLParser.parse(html);
    return root.text || "";
}

export const partitionReviewArray = (list, typeFA,typeCA) => {
    const [financialAdvisors, charteredAccountants, investors] =
        list.reduce((result, element) => {
                const reviewCat = element.node.review_cat[0];
                if (typeFA === reviewCat) {
                    result[0].push(element);
                } else if (typeCA === reviewCat) {
                    result[1].push(element);
                } else {
                    result[2].push(element)
                }
            return result;
            },
    [[], [], []]);

    return {financialAdvisors, charteredAccountants, investors};
}

export const getHomeFeaturedReviews = (list, typeFA, typeCA) => {
    var {financialAdvisors, charteredAccountants, investors} = partitionReviewArray(list, typeFA, typeCA);

    financialAdvisors = shuffleArray(financialAdvisors);
    charteredAccountants = shuffleArray(charteredAccountants);
    investors = shuffleArray(investors);

    const reviewsList = [];

    for (var i = 0; i < 5; i++) {
        reviewsList.push(financialAdvisors[i])
        reviewsList.push(charteredAccountants[i])
        reviewsList.push(investors[i])
    };

    return reviewsList;
}

export const getReviewDateText = (date) => {
    // const past_date = new Date(date);

    // const years = getYearsBeforeToday(past_date);
    // var dateText = years + ' years ago';

    // if (years < 2) {
    //     const months = getMonthsBeforeToday(past_date);

    //     dateText = months + ' months ago';
    
    //     if (months < 1) {
    //         const days = getDaysBeforeToday(past_date);
    //         dateText = days + ' days ago';
    //     }
    // }

    var dateText = formatDate(date);

    return dateText;
}

export const getYearsBeforeToday = (past_date) => {
    var current_date = new Date();
    return current_date.getFullYear() - past_date.getFullYear();
}

export const getMonthsBeforeToday = (past_date) => {
    var current_date = new Date();
    return (current_date.getFullYear()*12 + current_date.getMonth()) - (past_date.getFullYear()*12 + past_date.getMonth());
}

export const getDaysBeforeToday = (past_date) => {
    var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
    var current_date = new Date();

    return Math.round(Math.abs((current_date.getTime() - past_date.getTime())/(oneDay)));
}

export const shuffleArray = (oldArray) => {
    var array = [...oldArray]
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

export const getAdditionalServicesString = (additionalServices) => {
    var additionalServicesString = "";

    for (var key in additionalServices) {
        if (additionalServices[key]) {
            additionalServicesString += (key + " ");
        }
    }

    return additionalServicesString.substring(0,additionalServicesString.length-1);
}

export const getAdditionalServicesTooltipText = (additionalServices, isAdvisor, isPro) => {
    var additonalServicesTooltipText = [];

    additionalServices.forEach((service,index) => {
        var serviceName;
        if (service == Additional_Services.XIRR_INVESTORREPORTS) {
            serviceName = `MProfit ${isAdvisor ? 'Advisor' : (isPro ? 'Pro' : 'Investor')} Premium Pack`;
        } else {
            serviceName = Additional_Services_Name[service];
        }
        additonalServicesTooltipText.push("- " + serviceName);
    });

    return additonalServicesTooltipText.length > 0 ? additonalServicesTooltipText : null;
}

export const parseBrowserStorageValueForNulls = (value) => {
    return (value && value !== '' && value !== 'undefined' && value !== 'null') ? value : null;
}

export const getCloudSignupProductIdFromDesktop = (desktopProductId) => {
    if (!desktopProductId || desktopProductId < 100 || desktopProductId >= 200) {
        return -999;
    }

    if (desktopProductId >= 103) {
        return 306;
    }

    switch (desktopProductId) {
        case 100:
            return 300;
        case 101:
            return 300;
        case 102:
            return 302;
    }

    return -999;
}

export const getDesktopDownloadProductIdFromCloud = (cloudProductId) => {
    if (!cloudProductId || cloudProductId < 300 || cloudProductId >= 400) {
        return -999;
    }

    if (cloudProductId >= 303) {
        return 103;
    }

    switch (cloudProductId) {
        case 300:
        case 301:
            return 101;
        case 302:
            return 102;
    }

    return -999;
}

export const getCloudB2CProductIdFromTier = (b2cTier) => {
    switch (b2cTier) {
        case B2C_Tier.FREE:
        case B2C_Tier.LITE:
            return CloudB2C_ProductIDs.LITE;
        case B2C_Tier.PLUS:
            return CloudB2C_ProductIDs.PLUS;
        case B2C_Tier.HNI:
            return CloudB2C_ProductIDs.HNI;
        case B2C_Tier.FAMILYOFFICE:
            return CloudB2C_ProductIDs.FAMILYOFFICE;
    }

    return CloudB2C_ProductIDs.PLUS;
}

export const getCloudB2CProductIdFromClientType = (clientType) => {
    switch (clientType) {
        case Client_Type.B2C_NEW_INVESTOR:
            return CloudB2C_ProductIDs.PLUS;
        case Client_Type.B2C_EXPERIENCED_INVESTOR:
            return CloudB2C_ProductIDs.PLUS;
        case Client_Type.B2C_HNI:
            return CloudB2C_ProductIDs.HNI;
        case Client_Type.B2C_FAMILYOFFICE:
            return CloudB2C_ProductIDs.FAMILYOFFICE;
    }

    return CloudB2C_ProductIDs.PLUS;
}

export const getCloudB2B2CProductIdFromTier = (b2b2cTier, numPortfolios) => {
    switch (b2b2cTier) {
        case B2B2C_Tier.ESSENTIAL:
            return CloudB2B2C_ProductIDs.ESSENTIAL['P'+numPortfolios];
        case B2B2C_Tier.ADVANCED:
            return CloudB2B2C_ProductIDs.ADVANCED['P'+numPortfolios];
        case B2B2C_Tier.PREMIUM:
            return CloudB2B2C_ProductIDs.PREMIUM['P'+numPortfolios];
        case B2B2C_Tier.STANDARD:
            return CloudB2B2C_ProductIDs.STANDARD['P'+numPortfolios];
        case B2B2C_Tier.PLATINUM:
            return CloudB2B2C_ProductIDs.PLATINUM['P'+2000];
    }

    return CloudB2B2C_ProductIDs.ESSENTIAL['P'+numPortfolios];
}

export const getB2CPricingCalculatorFieldsInfo = (b2cTier) => {
    var showUsers = false, showFamilies = false, showPortfolios = false;
    var minUsers = 1, minFamilies = 1;
    switch (b2cTier) {
        case B2C_Tier.PLUS:
            showUsers = true;
            break;
        case B2C_Tier.HNI:
            showUsers = true;
            minUsers = 2;
            break;
        case B2C_Tier.FAMILYOFFICE:
            showUsers = true;
            minUsers = 4;
            minFamilies = 3;
            break;
    }

    return {showUsers, showFamilies, showPortfolios, minUsers, minFamilies};
}

export const getB2B2CPricingCalculatorFieldsInfo = (b2b2cTier) => {
    var showUsers = true, showPortfolios = true, showFamilies = false;
    var minUsers = 1, minFamilies = -1;

    switch (b2b2cTier) {
        case B2B2C_Tier.STANDARD:
            minUsers = 1;
            break;
        case B2B2C_Tier.PLATINUM:
            showUsers = false;
            showPortfolios = false;
            break;
    }

    return {showUsers, showFamilies, showPortfolios, minUsers, minFamilies};
}

export const getNumPortfoliosFromSliderMarks = (mark) => {
    switch(mark) {
        case 0:
            return 100;
        case 1:
            return 200;
        case 2:
            return 500;
        case 3:
            return 1000;
        case 4:
            return -1;
    }
}

export const loginToMProfitCloudWithAffiliateLinkIfExists = () => {
    var link = 'https://cloud.mprofit.in';
    var searchParamsFromStorage = window.localStorage.getItem("urlSearchParams");
    if (searchParamsFromStorage) {
      const searchParamsParsed = queryString.parse(searchParamsFromStorage, {arrayFormat: 'bracket'});
      if (searchParamsParsed && searchParamsParsed.a) {
        var affiliateUTM = searchParamsParsed.a;
        link += '?a=' + affiliateUTM;
      }
    }

    window.open(link, '_blank');
}

export const getIsUpgradeToNewPlansRequired = (plan) => {
    if (plan.IsLegacyProduct !== true) {
        return false;
    }
    
    if (plan.IsExpired || plan.RenewalEligible) {
        return true;
    } else {
        return false;
    }
}

export const getFinalPlanRecommendationDetails = (planRecommendation, isCloud) => {
    if (!planRecommendation.B2X) {
        return null;
    }
    var finalPlanRecommendation = {
        B2X: planRecommendation.B2X
    };

    if (planRecommendation.B2C_Tier != B2C_Tier.UNDEFINED) {
        finalPlanRecommendation.B2C = {
            ProductName: getB2CProductNameFromTierAndAUMLimit(planRecommendation.B2C_Tier, planRecommendation.B2C_CustomAUMLimit, isCloud),
            UsesPMSCashflowTracking: (planRecommendation.HasPMSPack) === true,
            HasPremiumPack: (planRecommendation.HasPremiumPack) === true,
            HasFNO: planRecommendation.HasFNO,
            TotalPortfolioCount: planRecommendation.TotalPortfolioCount,
            FamilyCount: planRecommendation.FamilyCount,
            AUMDisplay: planRecommendation.AUM === undefined ? undefined : getAUMInLakhsCrores(planRecommendation.AUM),
            ProductId: getB2CProductIdFromTier(planRecommendation.B2C_Tier, isCloud),
            CustomAUMLimit: planRecommendation.B2C_CustomAUMLimit,
            B2C_Tier: planRecommendation.B2C_Tier
        }
        // return finalPlanRecommendation;
    }

    if (planRecommendation.B2B2C_Tier != B2C_Tier.UNDEFINED) {
        planRecommendation.B2B2C_NumPortfolios = getNumPortsForB2B2CPlansFromProductId(planRecommendation.B2B2C_ProductId);
        var numPortsText = planRecommendation.B2B2C_NumPortfolios > 2000 ? '- ' + planRecommendation.B2B2C_NumPortfolios + ' ' : '';
        var customAUMText = "(" + (planRecommendation.B2B2C_CustomAUMLimit > 0 ? "₹ " + getAUMInCroresWithoutDecimalsAndWithCR(planRecommendation.B2B2C_CustomAUMLimit) + " Investment Value Limit" : '> ₹ 50000 CR Investment Value Limit') + ")";
        finalPlanRecommendation.B2B2C = {
            ProductName: 'Wealth Platinum ' + numPortsText + customAUMText,
            TotalPortfolioCount: planRecommendation.TotalPortfolioCount,
            FamilyCount: planRecommendation.FamilyCount,
            AUMDisplay: planRecommendation.AUM === undefined ? undefined : getAUMInLakhsCrores(planRecommendation.AUM),
            ProductId: planRecommendation.B2B2C_ProductId,
            CustomAUMLimit: planRecommendation.B2B2C_CustomAUMLimit,
            NumPortsAllowed: planRecommendation.B2B2C_NumPortfolios
        }
    }

    return finalPlanRecommendation;
}

const getNumPortsForB2B2CPlansFromProductId = (productId) => {
    return [263, 1213].includes(+productId) ? 10000 : 2000;
}

const getAUMInLakhsCrores = (AUM) => {
    if (AUM >= 10000000) {
        return '₹ ' + getAUMInCrores(AUM);
    } else if (AUM >= 100000) {
        return '₹ ' + getAUMInLakhs(AUM);
    } else if (AUM >= 10000) {
        return '₹ ' + AUM.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
    } else {
        return '₹ ' + Math.round(AUM, 0);
    }
}

const getAUMInCrores = (AUM) => {
    return (Math.round(AUM * 10 / 10000000) / 10).toFixed(1) + ' CR';
}

export const getAUMInCroresWithoutDecimalsAndWithCR = (AUM) => {
    return Math.round(AUM / 10000000) + ' CR';
}

export const getAUMInCroresWithoutDecimals = (AUM) => {
    return Math.round(AUM / 10000000);
}

const getAUMInLakhs = (AUM) => {
    return (Math.round(AUM * 10 / 100000)) / 10 + ' L';
}

export const getB2CProductNameFromTierAndAUMLimit = (b2cTier, customAUMLimit, isCloud) => {
    console.log("🚀 ~ getB2CProductNameFromTierAndAUMLimit ~ b2cTier:", b2cTier)
    switch (b2cTier) {
        case B2C_Tier.DESKTOP_LITE:
            return "Desktop Lite";
        case B2C_Tier.DESKTOP_PLUS:
            return "Desktop Plus";
        case B2C_Tier.DESKTOP_HNI:
            return "Desktop HNI";
        case B2C_Tier.DESKTOP_MULTIFAMILY:
            return "Desktop Family Office (" + (customAUMLimit > 0 ? "₹ " + getAUMInCroresWithoutDecimalsAndWithCR(customAUMLimit) + " Investment Value Limit" : 'No Investment Value Limit') + ")";
        case B2C_Tier.LITE:
            return (isCloud ? "" : "Desktop ") + "Lite";
        case B2C_Tier.PLUS:
            return (isCloud ? "" : "Desktop ") + "Plus";
        case B2C_Tier.HNI:
            return "HNI";
        case B2C_Tier.FAMILYOFFICE:
            return "Family Office (" + (customAUMLimit > 0 ? "₹ " + getAUMInCroresWithoutDecimalsAndWithCR(customAUMLimit) + " Investment Value Limit" : 'No Investment Value Limit') + ")";
    }
}

export const getB2CProductIdFromTier = (b2cTier, isCloud) => {
    switch (b2cTier) {
        case B2C_Tier.DESKTOP_LITE:
            return DesktopB2C_ProductIDs.DESKTOP_LITE;
        case B2C_Tier.LITE:
            return isCloud ? CloudB2C_ProductIDs.LITE : DesktopB2C_ProductIDs.DESKTOP_LITE;
        case B2C_Tier.DESKTOP_PLUS:
            return DesktopB2C_ProductIDs.DESKTOP_PLUS;
        case B2C_Tier.PLUS:
            return isCloud ? CloudB2C_ProductIDs.PLUS : DesktopB2C_ProductIDs.DESKTOP_PLUS;
        case B2C_Tier.DESKTOP_HNI:
            return DesktopB2C_ProductIDs.DESKTOP_SINGLEFAMILY;
        case B2C_Tier.HNI:
            return CloudB2C_ProductIDs.HNI;
        case B2C_Tier.DESKTOP_MULTIFAMILY:
            return DesktopB2C_ProductIDs.DESKTOP_MULTIFAMILY;
        case B2C_Tier.FAMILYOFFICE:
            return CloudB2C_ProductIDs.FAMILYOFFICE;
    }
}

export const getCustomAUMLimitFromOption_DesktopOnly = (customAUMLimitOption) => {
    if (!customAUMLimitOption || customAUMLimitOption <= 0) {
        return customAUMLimitOption;
    } else {
        return customAUMLimitOption * 250000000;
    }
}

export const getDropdownText = (selectedID, options) => {
    var selected = options.find(x => x.id === selectedID);
    return selected ? selected.text : null;
}

export const getDropdownCustomValue = (selectedID, options) => {
    var selected = options.find(x => x.id === selectedID);
    return selected ? selected.value : null;
}

export const getCustomDropdownOptionIDFromValue = (value, options) => {
    console.log("🚀 ~ getCustomDropdownOptionIDFromValue ~ value:", value, options)
    var selected = options.find(x => x.value === value);
    return selected ? selected.id : -999;
}


export const getCustomAUMOptionList = (b2x) => {
    switch (b2x) {
        case B2X.B2B2C:
            return CustomAUMLimitDropdown_B2B2C;
        default:
            return CustomAUMLimitDropdown_B2C;
    }
}

export const getIsCustomAUMDropdownShown = (productId, b2b2cTier) => {
    return [CloudB2C_ProductIDs.FAMILYOFFICE, DesktopB2C_ProductIDs.DESKTOP_MULTIFAMILY].includes(productId) || [B2B2C_Tier.PLATINUM].includes(b2b2cTier);
}